import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import LogoutIcon from '@mui/icons-material/Logout';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FaceIcon from '@mui/icons-material/Face';
import CampaignIcon from '@mui/icons-material/Campaign';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';

const menu = [
    {
        title: 'Orders',
        path: '/orders',
        icon: <ShoppingCartIcon />
    },
    {
        title: 'Customers',
        path: '/customers',
        icon: <FaceIcon />
    },
    {
        title: 'Loyalty Tiers',
        path: '/loyalty_tiers',
        icon: <LoyaltyIcon />
    },
    {
        title: 'Referral',
        path: '/referral',
        icon: <FaceRetouchingNaturalIcon />
    },
    {
        title: 'Marketing Promotions',
        path: '/campaigns',
        icon: <CampaignIcon />
    },
    {
        title: 'Settings',
        icon: <SettingsIcon />,
        children: [
            {
                title: 'General',
                path: '/settings/general',
                icon: <DisplaySettingsIcon />
            },
            {
                title: 'Manage Users',
                icon: <ManageAccountsIcon />,
                path: '/settings/users',
            },
            {
                title: 'Billing',
                icon: <AttachMoneyIcon />,
                path: '/settings/billing',
            }
        ]
    },
    {
        title: 'Profile',
        icon: <AccountCircleIcon />,
        path: '/profile',
    },
    {
        title: 'Contact',
        icon: <SupportAgentIcon />,
        path: '/contact',
    },
    {
        title: 'Logout',
        icon: <LogoutIcon />,
        path: '/logout',
    }
]

export default menu;