import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import "./App.css";

import MiniDrawer from "./common/components/drawer/MiniDrawer";
import Profile from "./common/views/Profile";
import Billing from "./common/views/Settings/Billing";
import Cards from "./common/views/Settings/Cards";
import CheckoutForm from './common/views/Settings/CheckoutForm';
import Users from "./common/views/Settings/Users";

import Orders from "./views/Orders";
import Customers from "./views/Customers";

import Campaigns from './views/Campaigns/Campaigns';
import MembershipTiers from "./views/MembershipTiers";
import Referral from "./views/Referral";
import General from "./views/General";

import ManuBar from "./common/components/ManuBar";
import Login from './common/views/Public/Login';
import Logout from './common/views/Logout';
import Register from './common/views/Public/Register';
import ForgotPasswordAllInOne from "./common/views/Public/ForgotPasswordAllInOne";
import ResetPassword from "./common/views/Public/OldResetPassword";
import CreatePassword from "./common/views/Public/CreatePassword";
import VerifyEmail from "./common/views/Public/VerifyEmail";

import Unauthorized from "./common/views/Unauthorized";
import Error from './common/views/Error';
import RequireAuth from "./common/views/RequireAuth";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PersistLogin from "./common/views/PersistLogin";
import { useLogoutAllTabs } from './common/hooks/useBroadcast';
import Contact from './common/views/Contact';

function App() {
  const theme = createTheme();
  const logoutAllTabs = useLogoutAllTabs();

  useEffect(() => {
    logoutAllTabs();
  }, [logoutAllTabs])

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/" element={<ManuBar />} >
            <Route index element={<Login />} />
            <Route path="index.html" element={<Login />} />
            <Route path="api/v1/bigcommerce/load" element={<Login isLoad={true} />} />
            <Route path="api/v1/bigcommerce/auth" element={<Login isLoad={true} />} />
            <Route path="login" element={<Login />} />
            <Route path='register' element={<Register />} />

            <Route path='user/verify_email' exact element={<VerifyEmail />} />
            <Route path='user/forgot_password' exact element={<ForgotPasswordAllInOne />} />

            {/* Following is used by forgot password + reset password ( version 1 ) */}
            <Route path='user/reset_password' exact element={<ResetPassword />} />
            {/* Following is used by invite Email */}
            <Route path='signup/complete' exact element={<CreatePassword />} />
          </Route>

          {/* we want to protect these routes*/}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={['owner', 'admin', 'user']} />} >
              <Route path="/orders" element={<MiniDrawer />} >
                <Route index element={<Orders />} />
              </Route>

              <Route path="/customers" element={<MiniDrawer />} >
                <Route index element={<Customers />} />
              </Route>

              <Route path="/loyalty_tiers" element={<MiniDrawer />} >
                <Route index element={< MembershipTiers />} />
              </Route>

              <Route path="/referral" element={<MiniDrawer />} >
                <Route index element={< Referral />} />
              </Route>

              <Route path="/campaigns" element={<MiniDrawer />} >
                <Route index element={<Campaigns />} />
              </Route>

              <Route path="/manage" element={<MiniDrawer />} >
                <Route path="unauthorized" element={<Unauthorized />} />
              </Route>


              <Route path="/settings" element={<MiniDrawer />} >
                <Route index element={<General />} />
                <Route path="general" element={<General />} />
                <Route path='users' element={<Users />} />
              </Route>

              <Route path="/profile" element={<MiniDrawer />} >
                <Route index element={<Profile />} />
              </Route>

              <Route path="/contact" element={<MiniDrawer />} >
                <Route index element={<Contact />} />
              </Route>

              <Route path="/logout" element={<Logout />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={['owner', 'admin']} />} >
              <Route path="/settings" element={<MiniDrawer />} >
                <Route path='billing' element={<Billing />} />
                <Route path='subscribe' element={<CheckoutForm />} />
                <Route path='payment_methods' element={<Cards />} />
              </Route>
            </Route>

          </Route>

          {/* Catch all */}
          <Route path='*' element={<Error />} />

        </Routes>
      </div>
    </ThemeProvider>

  );
}


export default App;
